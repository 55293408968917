<template>
	<bg-modal
		:value="isOpen"
		title="Yakin ingin hapus role?"
		description="Data role yang sudah dihapus tidak dapat dikembalikan."
		desktop-size="sm"
		:closable="false"
		:close-on-click-backdrop="false"
	>
		<template #footer>
			<div class="flex justify-end">
				<bg-button @click="closeModal">Batal</bg-button>
				<bg-button
					:loading="isLoading"
					variant="primary"
					class="ml-16"
					@click="deleteRole"
					>Hapus</bg-button
				>
			</div>
		</template>
	</bg-modal>
</template>

<script>
import { BgModal, BgButton } from 'bangul-vue';
import RoleManagementApi from '@admin_endpoints/role-management';

export default {
	name: 'DeleteRoleModal',

	components: {
		BgModal,
		BgButton,
	},

	props: {
		isOpen: {
			type: Boolean,
			required: true,
		},
		roleId: {
			type: [String, Number],
			default: null,
		},
	},

	data() {
		return {
			isLoading: false,
		};
	},

	methods: {
		closeModal() {
			this.$emit('update:isOpen', false);
			this.$emit('update:roleId', null);
		},

		async deleteRole() {
			if (this.roleId !== null) {
				this.isLoading = true;

				try {
					await RoleManagementApi.deleteRole(this.roleId);

					this.$emit('action-success-delete');
				} catch (error) {
					console.error(error);
					this.$emit('action-fail-delete');
				} finally {
					this.isLoading = false;
					this.closeModal();
				}
			}
		},
	},
};
</script>
